import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import pdsquare from '../Img/portfolio/pd.png'
import earthengineers from '../Img/portfolio/earthengineers.png'
import payzall from '../Img/portfolio/payzall.png'
import hiscilab from '../Img/portfolio/hiscilab.png'
import sdk from '../Img/portfolio/sdk.png'
import elite from '../Img/portfolio/elite.png'
import manah from '../Img/portfolio/manah.png'
import brandpost from '../Img/portfolio/brandpost.png'

export default function products() {

    return (
        <section className='products'>
            <Container>
                <Row className='justify-content-center'>
                    <Col lg={5} md={6} sm={12} data-aos="fade-up" className='my-2'>
                        <div className="portfolioitem">
                            <div className='clients'>
                                <img className='w-100' src={pdsquare} alt="" />
                            </div>
                            <div className='clients-info'>
                                <div className='portfolio-main'>
                                    <div className='info-sub'>
                                        <h4>PD Square</h4>
                                        <p>We are manufacturing products with world class quality</p>
                                    </div>
                                    <div className='info-sub-2'>
                                        <a href=' https://www.pd-square.com/ ' target="_blank">
                                            <Button className='quality'><svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 13L14 7L8 1M13.1667 7H1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg></Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={5} md={6} sm={12} data-aos="fade-up" className='my-2'>
                        <div className="portfolioitem">
                            <div className='clients'>
                                <img className='w-100' src={earthengineers} alt="" />
                            </div>
                            <div className='clients-info'>
                                <div className='portfolio-main'>
                                    <div className='info-sub'>
                                        <h4>Earthengineers</h4>
                                        <p>We at Earth, have a team of experienced professionals from varied background of</p>
                                    </div>
                                    <div className='info-sub-2'>
                                        <a href=' https://earthengineers.in/ ' target="_blank">
                                            <Button className='quality quality-2'><svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 13L14 7L8 1M13.1667 7H1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg></Button>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={5} md={6} sm={12} data-aos="fade-up" className='my-2'>
                        <div className="portfolioitem">
                            <div className='clients'>
                                <img className='w-100' src={payzall} alt="" />
                            </div>
                            <div className='clients-info'>
                                <div className='portfolio-main'>
                                    <div className='info-sub'>
                                        <h4>Paysall</h4>
                                        <p>Paysall is India's most popular payments & recharge app!</p>
                                    </div>
                                    <div className='info-sub-2'>
                                        <a href=' https://play.google.com/store/apps/details?id=com.bdmtrip.paysall&hl=en_IN&gl=US&pli=1 ' target="_blank">
                                            <Button className='quality quality-3'><svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 13L14 7L8 1M13.1667 7H1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg></Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={5} md={6} sm={12} data-aos="fade-up" className='my-2'>
                        <div className="portfolioitem">
                            <div className='clients'>
                                <img className='w-100' src={hiscilab} alt="" />
                            </div>
                            <div className='clients-info'>
                                <div className='portfolio-main'>
                                    <div className='info-sub'>
                                        <h4>HISCI Lab</h4>
                                        <p>Safety starts with understanding how developers collect and share your data.</p>
                                    </div>
                                    <div className='info-sub-2'>
                                        <a href=' https://play.google.com/store/apps/details?id=com.alphamanias.hiscilabs ' target="_blank">
                                            <Button className='quality quality-4'><svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 13L14 7L8 1M13.1667 7H1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg></Button>

                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={5} md={6} sm={12} data-aos="fade-up" className='my-2'>
                        <div className="portfolioitem">
                            <div className='clients'>
                                <img className='w-100' src={sdk} alt="" />
                            </div>
                            <div className='clients-info'>
                                <div className='portfolio-main'>
                                    <div className='info-sub'>
                                        <h4>Ottu</h4>
                                        <p>We provide a seamless integration with Apple Pay.</p>
                                    </div>
                                    <div className='info-sub-2'>
                                        <a href=' https://pub.dev/packages/ottu' target="_blank">
                                            <Button className='quality quality-5'><svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 13L14 7L8 1M13.1667 7H1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            </Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={5} md={6} sm={12} data-aos="fade-up" className='my-2'>
                        <div className="portfolioitem">
                            <div className='clients'>
                                <img className='w-100' src={elite} alt="" />
                            </div>
                            <div className='clients-info'>
                                <div className='portfolio-main'>
                                    <div className='info-sub'>
                                        <h4>Egrove</h4>
                                        <p>Developers can show information here about how their app collects and uses your data. </p>
                                    </div>
                                    <div className='info-sub-2'>
                                        <a href=' https://play.google.com/store/apps/details?id=com.egrove.eliteonestore&hl=en ' target="_blank">
                                            <Button className='quality quality-6'><svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 13L14 7L8 1M13.1667 7H1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg></Button>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={5} md={6} sm={12} data-aos="fade-up" className='my-2'>
                        <div className="portfolioitem">
                            <div className='clients'>
                                <img className='w-100' src={manah} alt="" />
                            </div>
                            <div className='clients-info'>
                                <div className='portfolio-main'>
                                    <div className='info-sub'>
                                        <h4>MANAh Wellness</h4>
                                        <p>We are wired to share stories, especially those that bind us to others.</p>
                                    </div>
                                    <div className='info-sub-2'>
                                        <a href=' https://www.manahwellness.com/' target="_blank">
                                        <Button className='quality quality-7'><svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 13L14 7L8 1M13.1667 7H1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        </Button>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={5} md={6} sm={12} data-aos="fade-up" className='my-2'>
                        <div className="portfolioitem">
                            <div className='clients'>
                                <img className='w-100' src={brandpost} alt="" />
                            </div>
                            <div className='clients-info'>
                                <div className='portfolio-main'>
                                    <div className='info-sub'>
                                        <h4>Brandpost</h4>
                                        <p>Now Expand Your Business With Creative Marketing Images</p>
                                    </div>
                                    <div className='info-sub-2'>
                                        <a href=' http://brandpostapp.com/' target="_blank">
                                        <Button className='quality quality-8'><svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 13L14 7L8 1M13.1667 7H1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        </Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
