import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import phone from '../Img/phone.png'


export default function UserFeatures() {
    return (
        <section className='UserFeatures'>
            <Container>
                <div className='round-shape'>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span className='bg-color-2'></span>
                </div>
            </Container>
            <Container className='mb-4'>
                <div class="design_2">
                    <span></span>
                    <h4>Features</h4>
                </div>
            </Container>
            <Container>
                <Row className='justify-content-center align-items-center'>
                    <Col lg={4}>
                        <div className='Discover'>
                            <div className="Shuffle-Mode right">
                                <h3>Discover</h3>
                                <p>Lean back and discover new tracks with Shuffle Mode,Get personalized recommendations and discover music with Flow.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode right">
                                <h3>Favourites</h3>
                                <p>Add the tracks you love to your music collection in a single tap with Favourites.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode right">
                                <h3>Artist</h3>
                                <p>Play songs, stream albums and hits from all of your favourite artists.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode right">
                                <h3>Genre</h3>
                                <p>Organise and discover incredible music by genre.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode right">
                                <h3>Offline Access  </h3>
                                <p>Take your collection offline, everywhere you go.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode right">
                                <h3>Liked </h3>
                                <p>Display all your liked sound tracks.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode right">
                                <h3>Favourites </h3>
                                <p>Display all your favorited sound tracks.</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={10}>
                        <div className="Discover img-center">
                            <img className='w-70 text-center' src={phone} alt="" />
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className='Discover'>
                            <div className="Shuffle-Mode">
                                <h3>Playlists</h3>
                                <p>Create playlists for parties, workouts, on the way to work, or whatever mood you’re in</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode">
                                <h3>Recently Played</h3>
                                <p>Display all your Recently sound tracks which you played.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode">
                                <h3>Downloads</h3>
                                <p>Display all your downloaded sounds and musics.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode">
                                <h3>Shared</h3>
                                <p>Display all your last shared sounds and links.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode">
                                <h3>Top Albums</h3>
                                <p>Play and display all kind of albums in your app filtered by top visits.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode">
                                <h3>Notifications</h3>
                                <p>Display last activities and notification from your frinds and users.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode">
                                <h3>Admob</h3>
                                <p>Admob integration is available</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
