import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import axios from 'axios';
import BASEURlAPI from '../BaseurlApi';
import loader from '../Img/loader.gif'
import MydModal from './MydModal'

export default function JobPositions() {


    const [get, setget] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [modalShow, setModalShow] = useState(false);

    const [isloading, setisloading] = useState(false);
    const [name, setName] = useState("");
    const [lname, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [imageurl, setImage] = useState("")
    const required = value => (value ? undefined : "Required");
    
    const [description, setdescription] = useState("");
    const [title, settitle] = useState("")

    useEffect(() => {
        axios.get(BASEURlAPI + 'api/Position/getAllPosition')
            .then(function (response) {
                console.log(response.data.data);
                setget(response.data.data)
            })
            .catch(function (error) {
                console.log(error);
            })
    }, [])

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }



    const savedata = async (event) => {

        event.preventDefault();
        document.getElementById("demo")
        var file = document.getElementById("demo").files[0];
        const base64 = await convertBase64(file);
        const data = JSON.stringify({
            "First_Name": name,
            "Last_Name": lname,
            "Email": email,
            "Mobile_Number": number,
            "Resume": base64,
        });

        try {
            setisloading(true)
            var config = {
                method: 'post',
                url: BASEURlAPI + 'api/Contact/addContact',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            console.log(data)
            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    setisloading(false)
                    window.location.reload()
                })
                .catch(function (error) {
                });
        } catch (e) {
            setisloading(false)

        }
    }

    return (
        <section className='JobPositions' data-aos="fade-up">
            <Container>
                <div className='design_2'>
                    <span></span>
                    <h4>Available Positions</h4>
                    <p>We are always looking for creative, talented self-starters to <br></br> join the Evenmore Infotech family. Check out our open positions and fill out an application.</p>
                </div>
                <div className='sub-position'>
                    <Row>
                        {
                            get.map((alldata, index) => {
                                return (
                                    <>
                                        <Col lg={4} md={6} className='mb'>
                                            <div className='main-div-position'>
                                                <img className='mb-4' src={alldata.position_image.replaceAll('localhost', '192.168.1.10')} alt="" />
                                                <h3>{alldata.title}</h3>
                                                <ul>
                                                    <li>
                                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M18.336 10.0105C18.4026 10.048 18.416 10.1275 18.4186 10.1578L18.4193 10.1699L18.3452 14.9502C18.3452 16.857 16.8678 18.412 15.0074 18.5073L14.8262 18.5119H3.67645C1.7925 18.5119 0.256186 17.0165 0.162006 15.1335L0.15743 14.9502L0.0926059 10.1699C0.0926059 10.1699 0.0926059 10.0574 0.175951 10.0105C0.250036 9.96367 0.342642 10.0199 0.351902 10.0293C2.22254 11.2853 4.47287 12.1195 6.83432 12.4663C7.1677 12.5132 7.50108 12.2976 7.59369 11.9601C7.78468 11.2396 8.3745 10.7497 9.09608 10.6913L9.24207 10.6854H9.26985C10.057 10.6854 10.7145 11.1915 10.9182 11.9601C11.0108 12.2976 11.3442 12.5132 11.6776 12.4663C14.0391 12.1195 16.2894 11.2853 18.16 10.0293C18.16 10.0293 18.1785 10.0199 18.1971 10.0105C18.2341 9.99179 18.2897 9.98242 18.336 10.0105ZM9.25133 12.0258C8.90329 12.0258 8.60971 12.2935 8.5632 12.6342L8.55679 12.7287V13.9379C8.55679 14.3315 8.87165 14.6408 9.25133 14.6408C9.60786 14.6408 9.89436 14.3809 9.93964 14.0344L9.94587 13.9379V12.7287C9.94587 12.3444 9.64028 12.0258 9.25133 12.0258ZM10.4459 0C11.835 0 12.9741 1.05916 13.15 2.41825H14.9836C16.9284 2.41825 18.5119 4.02105 18.5119 5.9894C18.5119 5.9894 18.4564 6.82266 18.4378 7.98305C18.436 8.07491 18.3915 8.16489 18.3184 8.21925C17.8729 8.54825 17.4655 8.82007 17.4284 8.83881C15.8912 9.86985 14.1048 10.5953 12.2018 10.9562C12.0777 10.9806 11.9554 10.9159 11.8925 10.8053C11.359 9.8811 10.3626 9.27935 9.25133 9.27935C8.14747 9.27935 7.14177 9.87454 6.59261 10.7997C6.52872 10.9084 6.40833 10.9712 6.28516 10.9478C4.39785 10.586 2.61149 9.86141 1.08349 8.84818L0.194472 8.22956C0.120388 8.1827 0.0740847 8.09834 0.0740847 8.00461C0.046303 7.52658 0 5.9894 0 5.9894C0 4.02105 1.58356 2.41825 3.52829 2.41825H5.35262C5.52857 1.05916 6.66763 0 8.05671 0H10.4459ZM10.4459 1.40596H8.05671C7.43625 1.40596 6.9084 1.83712 6.76023 2.41825H11.7424C11.5943 1.83712 11.0664 1.40596 10.4459 1.40596Z" fill="#5B5B5B" />
                                                        </svg><span>{alldata.experience}</span>
                                                    </li>
                                                    <li>
                                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.25596 0C14.3745 0 18.5119 4.14667 18.5119 9.25596C18.5119 14.3745 14.3745 18.5119 9.25596 18.5119C4.14667 18.5119 0 14.3745 0 9.25596C0 4.14667 4.14667 0 9.25596 0ZM8.932 4.56319C8.55251 4.56319 8.2378 4.86864 8.2378 5.25739V9.93165C8.2378 10.1723 8.36739 10.3944 8.58028 10.524L12.2086 12.6899C12.3197 12.7547 12.44 12.7917 12.5696 12.7917C12.801 12.7917 13.0324 12.6714 13.162 12.4493C13.3564 12.1253 13.2545 11.6995 12.9213 11.4959L9.6262 9.53364V5.25739C9.6262 4.86864 9.3115 4.56319 8.932 4.56319Z" fill="#5B5B5B" />
                                                        </svg><span>{alldata.time}</span>
                                                    </li>
                                                    <li>
                                                        <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6.94197 11.584C10.7062 11.584 13.8839 12.2034 13.8839 14.5955C13.8839 16.9876 10.6858 17.5863 6.94197 17.5863C3.17776 17.5863 0 16.966 0 14.5748C0 12.1827 3.19722 11.584 6.94197 11.584ZM6.94197 0C9.49159 0 11.5354 2.07022 11.5354 4.65282C11.5354 7.23543 9.49159 9.30565 6.94197 9.30565C4.39235 9.30565 2.34858 7.23543 2.34858 4.65282C2.34858 2.07022 4.39235 0 6.94197 0Z" fill="#5B5B5B" />
                                                        </svg>
                                                        <span>{alldata.dev_position_id} Position</span>
                                                    </li>
                                                </ul>

                                                <p onClick={() => {setModalShow(true); settitle(alldata.title); setdescription(alldata.description)}}>See Details</p>
                                                <Button onClick={handleShow} className='Explore'>Apply Now <FontAwesomeIcon className='middle' color='#192734' icon={faArrowRight} /></Button>
                                            </div>
                                        </Col>
                                    </>

                                )
                            })
                        }
                    </Row>
                </div>
            </Container>

            <Container>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Apply for this job</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form method='post'>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control validate={required}
                                            type="text"
                                            onChange={(e) => {
                                                setName(e.target.value);
                                            }}
                                            name="First Name"
                                            placeholder="Enter Your First Name" />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control validate={required}
                                            onChange={(e) => setLastName(e.target.value)}
                                            type="text"
                                            placeholder="Enter Your Last Name" />
                                    </Form.Group>
                                </Col>
                                <Col lg={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control validate={required}
                                            type="email"
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="name@example.com" />
                                    </Form.Group>
                                </Col>
                                <Col lg={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Mobile Number</Form.Label>
                                        <Form.Control validate={required}
                                            type="number"
                                            onChange={(e) => setNumber(e.target.value)}
                                            placeholder="Enter Your Mobile Number" />
                                    </Form.Group>
                                </Col>
                                <Col lg={12}>
                                    <Form.Group className="mb-3 main-resume">
                                        <Form.Control className='resume' validate={required}
                                            type="file"
                                            id='demo' onChange={(e) => setImage(e.target.files[0])} name="image"
                                            placeholder="Upload Your Resume" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Modal.Footer>
                                {!isloading ?
                                    <Button className='Explore' type="button" onClick={savedata}>
                                        Submit <FontAwesomeIcon className='middle' color='#192734' icon={faArrowRight} />
                                    </Button> :
                                    <img width={'10%'} src={loader} alt='' />
                                }
                            </Modal.Footer>
                        </Form>
                    </Modal.Body>
                </Modal>

            </Container>
            <MydModal desc={description} title={title} show={modalShow} onHide={() => setModalShow(false)}></MydModal>                    
        </section >
    )
}
