
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Container } from "react-bootstrap";
import slider_1 from '../Img/1.png'
import slider_2 from '../Img/2.png'
import slider_3 from '../Img/3.png'
import slider_4 from '../Img/4.png'
import slider_5 from '../Img/5.png'
import slider_6 from '../Img/6.png'
import slider_7 from '../Img/7.png'
import slider_8 from '../Img/8.png'

export default function Mobile() {
    return (
        <section className='Mobile'>
            <Container fluid>
                <Swiper
                    breakpoints={{
                        300: {
                            slidesPerView: 2,
                        },
                        499: {
                            slidesPerView: 3,
                        },
                        999: {
                            slidesPerView: 5,
                        },
                        1100: {
                            slidesPerView: 7,
                        }
                    }}
                    slidesPerView={7}
                    spaceBetween={30}
                    freeMode={true}
                    grabCursor={true}
                    loop={true}
                    centeredSlides={true}
                    pagination={{
                        clickable: true,
                        el: Swiper-Pagination,

                    }}
                    modules={[FreeMode, Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={slider_1} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={slider_2} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={slider_3} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={slider_4} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={slider_5} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={slider_6} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={slider_7} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={slider_8} alt="" />
                        </div>
                    </SwiperSlide>
                </Swiper>
            </Container>
        </section>
    )
}
