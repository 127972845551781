import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Heroimg from '../Img/hero.png';
import Innerimg from '../Img/hero-img.png'



export default function Hero() {
  return (
    <div className='hero'>
      <Container>
        <Row>
          <Col lg={{ span: 6, order: 1 }} md={{ span: 12, order: 2 }} xs={{ span: 12, order: 2 }} className="my-2 align-self-center">
            <div className='hero-main_1' data-aos="fade-up">
              <h2>Digitalizing Businesses across the Globe</h2>
              <h1>Trusted Custom Software Development</h1>
              <div className='animation_wrapper'>
                <h4>Partner in &nbsp;</h4>
                <b>
                  <label className='animation_inner'>
                    <h3>E-commerce</h3>
                    <h3>Education</h3>
                    <h3>real estate</h3>
                    <h3>fintech</h3>
                  </label>
                </b>
              </div>
              <a href=' mailto:evenmore.in@gmail.com' target="_blank">
                <Button className='Explore my-4'>consult evenmore <FontAwesomeIcon className='middle' color='#192734' icon={faArrowRight} /></Button>
              </a>
            </div>
          </Col>
          <Col lg={{ span: 6, order: 1 }} md={{ span: 12, order: 1 }} xs={{ span: 12, order: 1 }} className='my-2' >
            <div className='hero-main_2'>
              <img className='w-100' src={Heroimg} alt="" />
              <div className='innerimg'>
                <img className='w-100' src={Innerimg} alt="" />
              </div>
            </div>
          </Col>
        </Row>

      </Container>
    </div>
  )
}
