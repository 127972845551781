import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Container } from "react-bootstrap";
import RelaxiooMobile_1 from '../Img/RelaxiooMobile/RelaxiooMobile-1.png'
import RelaxiooMobile_2 from '../Img/RelaxiooMobile/RelaxiooMobile-2.png'
import RelaxiooMobile_3 from '../Img/RelaxiooMobile/RelaxiooMobile-3.png'
import RelaxiooMobile_4 from '../Img/RelaxiooMobile/RelaxiooMobile-4.png'
import RelaxiooMobile_5 from '../Img/RelaxiooMobile/RelaxiooMobile-5.png'
import RelaxiooMobile_6 from '../Img/RelaxiooMobile/RelaxiooMobile-6.png'
import RelaxiooMobile_7 from '../Img/RelaxiooMobile/RelaxiooMobile-7.png'
import RelaxiooMobile_8 from '../Img/RelaxiooMobile/RelaxiooMobile-8.png'
import RelaxiooMobile_9 from '../Img/RelaxiooMobile/RelaxiooMobile-9.png'
import RelaxiooMobile_10 from '../Img/RelaxiooMobile/RelaxiooMobile-10.png'
import RelaxiooMobile_11 from '../Img/RelaxiooMobile/RelaxiooMobile-11.png'
import RelaxiooMobile_12 from '../Img/RelaxiooMobile/RelaxiooMobile-12.png'
import RelaxiooMobile_13 from '../Img/RelaxiooMobile/RelaxiooMobile-13.png'
import RelaxiooMobile_14 from '../Img/RelaxiooMobile/RelaxiooMobile-14.png'
import RelaxiooMobile_15 from '../Img/RelaxiooMobile/RelaxiooMobile-15.png'
import RelaxiooMobile_16 from '../Img/RelaxiooMobile/RelaxiooMobile-16.png'
import RelaxiooMobile_17 from '../Img/RelaxiooMobile/RelaxiooMobile-17.png'
import RelaxiooMobile_18 from '../Img/RelaxiooMobile/RelaxiooMobile-18.png'
import RelaxiooMobile_19 from '../Img/RelaxiooMobile/RelaxiooMobile-19.png'
import RelaxiooMobile_20 from '../Img/RelaxiooMobile/RelaxiooMobile-20.png'
import RelaxiooMobile_21 from '../Img/RelaxiooMobile/RelaxiooMobile-21.png'




export default function RelaxiooMobile() {
    return (
        <section className='Mobile'>
            <Container fluid>
                <Swiper
                    breakpoints={{
                        300: {
                            slidesPerView: 2,
                        },
                        499: {
                            slidesPerView: 3,
                        },
                        999: {
                            slidesPerView: 5,
                        },
                        1100: {
                            slidesPerView: 7,
                        }
                    }}
                    slidesPerView={7}
                    spaceBetween={30}
                    freeMode={true}
                    grabCursor={true}
                    loop={true}
                    centeredSlides={true}
                    pagination={{
                        clickable: true,
                        el: Swiper-Pagination,

                    }}
                    modules={[FreeMode, Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={RelaxiooMobile_1} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={RelaxiooMobile_2} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={RelaxiooMobile_3} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={RelaxiooMobile_4} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={RelaxiooMobile_5} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={RelaxiooMobile_6} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={RelaxiooMobile_7} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={RelaxiooMobile_8} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={RelaxiooMobile_9} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={RelaxiooMobile_10} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={RelaxiooMobile_11} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={RelaxiooMobile_12} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={RelaxiooMobile_13} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={RelaxiooMobile_14} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={RelaxiooMobile_15} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={RelaxiooMobile_16} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={RelaxiooMobile_17} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={RelaxiooMobile_18} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={RelaxiooMobile_19} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={RelaxiooMobile_20} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={RelaxiooMobile_21} alt="" />
                        </div>
                    </SwiperSlide>
                </Swiper>
            </Container>
        </section>
    )
}
