import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Feature2 from '../Img/Feature2.png'

export default function AppFeatures() {
  return (
    <section className='UserFeatures'>
            <Container>
                <div className='round-shape'>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span className='bg-color'></span>
                </div>
            </Container>
            <Container className='mb-4'>
                <div class="design_2">
                    <span></span>
                    <h4>Features</h4> 
                </div>
            </Container>
            <Container fluid>
                <Row className='justify-content-center'>
                    <Col lg={4}>
                        <div className='Discover'>
                            <div className="Shuffle-Mode right">
                                <h3>Near by Place</h3>
                                <p>Place finder helps to find out the shortest and easiest way to locate traveling time between two placements. The distance can be measured by kilometers.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode right">
                                <h3>Category</h3>
                                <p>The category namely School, Doctor, Banks, ATM, Food, Parks and miscellaneous can be search.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode right">
                                <h3>Images</h3>
                                <p>Viewing and organizing your various multiple photos.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode right">
                                <h3>Comments and Rating</h3>
                                <p>Login in should be used, as you do commenting. For the selected place, user can provide 1 to 5 rating.</p>
                            </div>
                        </div>
                       
                    </Col>
                    <Col lg={3} md={12}>
                        <div className="Discover text-center">
                            <img className='w-70 text-center' src={Feature2} alt="" />
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className='Discover'>
                            <div className="Shuffle-Mode">
                                <h3>Search</h3>
                                <p>“By search place”, you can locate wherever you desire.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode">
                                <h3>Navigation View</h3>
                                <p>Navigation View are modal elevated dialogs that come from the start/left side.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode">
                                <h3>Share Place</h3>
                                <p>This app makes it easy to share your near by places on social media.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode">
                                <h3>Easy to use</h3>
                                <p>It is spruce and so it is user amiable.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode">
                                <h3>Push Notification</h3>
                                <p>You can send push notifications to all app users easily from admin panel.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
  )
}
