import React from 'react'

export default function MOBILERELAXATION() {
    return (
        <div>
            <div class="design_2">
                <span></span>
            </div>
            <h3 className='mUSIC'>Simplest & Easiest Mobile Relaxation & Meditation Music</h3>
        </div>
    )
}