import React from 'react'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default function portfolio() {
    return (
        <section className='portfolio'>
            <Container>
                <div className='portfolio-main' data-aos="fade-up">
                    <div className='design_2'>
                        <span></span>
                        <h3>PORTFOLIO</h3>
                        <h4>Check Our Best Work</h4>
                        <p>Creating an identity and unique experience is what our forte. We strive to create a <br></br> unique experience through design concepts graphics and service design.</p>
                    </div>
                    <div className='tabs-main'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col lg={3} md={4} className="my-2">
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first"> <span>Mobile App development </span> <FontAwesomeIcon icon={faChevronRight} /> </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second"><span> web development</span> <FontAwesomeIcon icon={faChevronRight} /></Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="three"><span>User interfaces & experiences</span> <FontAwesomeIcon icon={faChevronRight} /></Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="four"><span>GRAPHICS DESIGN</span> <FontAwesomeIcon icon={faChevronRight} /></Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col lg={9}  md={8} className="my-2">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <div className='mobile'>
                                                <h3>Mobile App Development</h3>
                                                <p>Client-focused, creating strategic mobile solutions that deliver tangible business results, Evenmore mobile strategy consultants help brands navigate any and all facets of the digital landscape.</p>
                                                <ul>
                                                    <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                                    </svg>
                                                        <span>Android App Development</span>
                                                    </li>
                                                    <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                                    </svg>
                                                        <span>iOS App Development</span>
                                                    </li>
                                                    <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                                    </svg>
                                                        <span>Flutter App Development</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <div className='mobile'>
                                                <h3>WEB Development</h3>
                                                <p>We leverage long-standing experience in building and offering custom web application helping you implement the right technologies in areas that are best suited to bring your business value.</p>
                                                <Row>
                                                    <Col lg={6}>


                                                        <h3 className='part-div'>Frontend :</h3>
                                                        <ul>
                                                            <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                                            </svg>
                                                                <span>HTML/CSS/JS</span>
                                                            </li>
                                                            <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                                            </svg>
                                                                <span>Bootstrap 4-5</span>
                                                            </li>
                                                            <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                                            </svg>
                                                                <span>Responsive Web Design</span>
                                                            </li>
                                                            <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                                            </svg>
                                                                <span>Interactive Animation Website</span>
                                                            </li>
                                                            <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                                            </svg>
                                                                <span>Email Template</span>
                                                            </li>
                                                        </ul>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <h3 className='part-div'>Backend :</h3>
                                                        <ul>
                                                            <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                                            </svg>
                                                                <span>Shopify</span>
                                                            </li>
                                                            <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                                            </svg>
                                                                <span>Wordpress</span>
                                                            </li>
                                                            <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                                            </svg>
                                                                <span>Prestashop</span>
                                                            </li>
                                                            <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                                            </svg>
                                                                <span>Web Speed Optimization</span>
                                                            </li>
                                                            <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                                            </svg>
                                                                <span>Ecommerce</span>
                                                            </li>
                                                        </ul>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="three">
                                            <div className='mobile'>
                                                <h3>User interfaces & experiences</h3>
                                                <p>We can design a great UI UX layout in such a way as to reduce your bounce rate and increase the time that users spend on your website. We are highly talented in giving you the best UI/UX service that will matter the most in your business. Evenmore can help you enhance your brand value by providing user-centric and feature-rich applications.</p>
                                                <ul>
                                                    <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                                    </svg>
                                                        <span>Web UI/UX Design</span>
                                                    </li>
                                                    <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                                    </svg>
                                                        <span>App UI/UX Design</span>
                                                    </li>
                                                    <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                                    </svg>
                                                        <span>Dashboard UI/UX Design</span>
                                                    </li>
                                                    <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                                    </svg>
                                                        <span>Prototype</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="four">
                                            <div className='mobile'>
                                                <h3>Graphics design</h3>
                                                <p>We can design a great UI UX layout in such a way as to reduce your bounce rate and increase the time that users spend on your website. We are highly talented in giving you the best UI/UX service that will matter the most in your business. Evenmore can help you enhance your brand value by providing user-centric and feature-rich applications.</p>
                                                <ul>
                                                    <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                                    </svg>
                                                        <span>Social Media Design</span>
                                                    </li>
                                                    <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                                    </svg>
                                                        <span>Promotion Banner</span>
                                                    </li>
                                                    <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                                    </svg>
                                                        <span>Business Card</span>
                                                    </li>
                                                    <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                                    </svg>
                                                        <span>Brochure Design</span>
                                                    </li>
                                                </ul>
                                                 </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>

                    </div>
                </div>
            </Container>
        </section>
    )
}
