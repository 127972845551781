import React from 'react'
import { Container } from 'react-bootstrap'
import Marquee from 'react-fast-marquee'

export default function Focus() {
    return (
        <section className='Focus'>
            <Container>
                <div className='text-center'>
                    <ul class="homeinfo">
                        <li> <Marquee >QUALITY FOCUS || USER CENTRIC || INSIGHTS DRIVEN </Marquee> </li>
                    </ul>
                </div>
            </Container>
        </section>
    )
}
