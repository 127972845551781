import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export default function ExtraFacility() {
    return (
        <section className='ExtraFacility'>
            <Container>
                <Row>
                    <Col lg={6} md={6} sm={12}  data-aos="fade-down" className='align-self-center'>
                        <div className='Convenient'>
                            <div className='design'>
                                <span></span>
                                <h3>Extra Facility</h3>
                                <h4 className='Developer'>Why Will This App Be More Convenient for Your Health?</h4>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} data-aos="fade-down" >
                        <div className='Fully-responsive'>
                            <ul>
                                <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                </svg>
                                    <span>We build Powerful Websites</span>
                                </li>
                                <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                </svg>
                                    <span>Easy to install and login </span>
                                </li>
                                <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                </svg>
                                    <span>Provides a dynamic system</span>
                                </li>
                                <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                </svg>
                                    <span>Share Music</span>
                                </li>
                                <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                </svg>
                                    <span>Explore latest Relaxation Music and new releases</span>
                                </li>
                                <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                </svg>
                                    <span>Profile settings of your app</span>
                                </li>
                                <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                </svg>
                                    <span>Light mode and dark mode</span>
                                </li>
                                <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                </svg>
                                    <span>RTL supported</span>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>

        </section>
    )
}
