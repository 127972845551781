import React from 'react'
import { Container } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel';
import Testi from '../Img/testi.png'

export default function ClientsSay() {
    return (
        <section className='ClientsSay'>
            <Container>
                <div className='design_2'>
                    <span></span>
                    <h3>OUR CLIENTS SAY</h3>
                    <h4>Feedback From Our Clients</h4>
                </div>
                <Carousel indicators={false}>
                    <Carousel.Item>
                        <div className='sub-data'>
                            <img className='mb-3' src={Testi} alt='' />
                            <h3 className='text-dark'>werewolves196910</h3>
                            <p text-dark>"Customer support was quick and exact for what i needed to get this to run properly and sync to the admin panel. <br></br>I highly recommend this app and code. Everything syncs and compiles and it had no errors, <br></br>a few warnings but an easy fix. Great job dev team keep it up..."</p>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='sub-data'>
                            <img className='mb-3' src={Testi} alt='' />
                            <h3 className='text-dark'>Leslie_Katherin</h3>
                            <p text-dark>"Very good code and excellent attention, before the doubts. I recomend you"</p>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='sub-data'>
                            <img className='mb-3' src={Testi} alt='' />
                            <h3 className='text-dark'>FVEE_LLC</h3>
                            <p text-dark>"The support is great and they are very responsive they were able to fix the issue in a short time. <br></br> The app features are amazing."</p>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='sub-data'>
                            <img className='mb-3' src={Testi} alt='' />
                            <h3 className='text-dark'>vitoryudi</h3>
                            <p text-dark>"The support team is very fast. And the code is clean and organized."</p>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </Container>
        </section>
    )
}
