import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Happy from '../Img/Happy clients.png'
import ProjectCompleted from '../Img/Projectcompleted.png'
import Experience from '../Img/Experiences.png'
import Awards from '../Img/award1.png'


export default function awards() {
    return (
        <div className='award'>
            <Container>
                <Row>
                    <Col lg={3} md={6} sm={6} xs={12} className="my-2">
                    <div className='award-sub'> </div>
                        <div className='happy'>
                            <div className='happy-client'></div>
                            <div className='design-box'>
                               <img src={Happy} alt="" />
                                <h3>300+</h3>
                                <p>Happy Clients</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={12} className="my-2">
                        <div className='happy'>
                            <div className='happy-client'></div>
                            <div className='design-box'>
                               <img src={ProjectCompleted} alt="" />
                                <h3>50+</h3>
                                <p>Projects Completed</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={12} className="my-2">
                    <div className='award-sub'></div>
                        <div className='happy'>
                            <div className='happy-client'></div>
                            <div className='design-box'>
                               <img src={Experience} alt="" />
                                <h3>6+</h3>
                                <p>Experience</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={12} className="my-2">
                        <div className='happy'>
                            <div className='happy-client'></div>
                            <div className='design-box'>
                               <img src={Awards} alt="" />
                                <h3>2</h3>
                                <p>Awards</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
