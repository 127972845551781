import React from 'react'
import {  Container } from 'react-bootstrap'

export default function ComeJoin(props) {
    

    return (
        <section className='ComeJoin'>
            <Container>
                <div className='design_2'>
                    <span></span>
                    <h4>COME JOIN US</h4>
                    <p>We are always looking for creative, talented self-starters to join the Evenmore Infotech family. <br></br> Check out our open positions and fill out an application.</p>
                </div>
            </Container>
           


        </section>
    )
}
