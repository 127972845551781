import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Container } from "react-bootstrap";
import Ebook_1 from '../Img/Ebook/Ebook-1.png'
import Ebook_2 from '../Img/Ebook/Ebook-2.png'
import Ebook_3 from '../Img/Ebook/Ebook-3.png'
import Ebook_4 from '../Img/Ebook/Ebook-4.png'
import Ebook_5 from '../Img/Ebook/Ebook-5.png'
import Ebook_6 from '../Img/Ebook/Ebook-6.png'
import Ebook_7 from '../Img/Ebook/Ebook-7.png'
import Ebook_8 from '../Img/Ebook/Ebook-8.png'
import Ebook_9 from '../Img/Ebook/Ebook-9.png'
import Ebook_10 from '../Img/Ebook/Ebook-10.png'
import Ebook_11 from '../Img/Ebook/Ebook-11.png'
import Ebook_12 from '../Img/Ebook/Ebook-12.png'
import Ebook_13 from '../Img/Ebook/Ebook-13.png'
import Ebook_14 from '../Img/Ebook/Ebook-14.png'
import Ebook_15 from '../Img/Ebook/Ebook-15.png'
import Ebook_16 from '../Img/Ebook/Ebook-16.png'
import Ebook_17 from '../Img/Ebook/Ebook-17.png'
import Ebook_18 from '../Img/Ebook/Ebook-18.png'

export default function EbookSlider() {
    return (
        <section className='Mobile'>
            <Container fluid>
                <Swiper
                    breakpoints={{
                        300: {
                            slidesPerView: 2,
                        },
                        499: {
                            slidesPerView: 3,
                        },
                        999: {
                            slidesPerView: 5,
                        },
                        1100: {
                            slidesPerView: 7,
                        }
                    }}
                    slidesPerView={7}
                    spaceBetween={30}
                    freeMode={true}
                    grabCursor={true}
                    loop={true}
                    centeredSlides={true}
                    pagination={{
                        clickable: true,
                        el: Swiper - Pagination,

                    }}
                    modules={[FreeMode, Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={Ebook_1} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={Ebook_2} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={Ebook_3} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={Ebook_4} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={Ebook_5} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={Ebook_6} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={Ebook_7} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={Ebook_8} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={Ebook_9} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={Ebook_10} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={Ebook_11} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={Ebook_12} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={Ebook_13} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={Ebook_14} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={Ebook_15} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={Ebook_16} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={Ebook_17} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={Ebook_18} alt="" />
                        </div>
                    </SwiperSlide>
                </Swiper>
            </Container>
        </section>
    )
}
