import React from 'react'
import { Container, Modal } from 'react-bootstrap'
import Parser from 'html-react-parser'

export default function MydModal(props) {

    return (
        <section>
            <Modal className='demo' {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <span className='me-3'>
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.0374 8.48761H3.28489L7.98249 13.3083C8.35791 13.6936 8.35791 14.3258 7.98249 14.7111C7.60706 15.0963 7.00061 15.0963 6.62519 14.7111L0.281511 8.20114C0.192273 8.10975 0.121474 8.00119 0.0731688 7.88169C0.0248632 7.76219 0 7.63408 0 7.5047C0 7.37533 0.0248632 7.24722 0.0731688 7.12772C0.121474 7.00821 0.192273 6.89966 0.281511 6.80827L6.61556 0.288472C6.70469 0.197015 6.81049 0.124467 6.92693 0.0749712C7.04337 0.025475 7.16818 1.92731e-09 7.29421 0C7.42025 -1.92731e-09 7.54505 0.0254751 7.66149 0.0749712C7.77794 0.124467 7.88374 0.197015 7.97286 0.288472C8.06198 0.379929 8.13268 0.488504 8.18091 0.607998C8.22914 0.727492 8.25397 0.855566 8.25397 0.984905C8.25397 1.11424 8.22914 1.24232 8.18091 1.36181C8.13268 1.48131 8.06198 1.58988 7.97286 1.68134L3.28489 6.51192H14.0374C14.5668 6.51192 15 6.95645 15 7.49976C15 8.04308 14.5668 8.48761 14.0374 8.48761Z" fill="#192734" />
                            </svg>
                        </span>{props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <div className='main-job-div'>     
                            {Parser(props.desc)}
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>
        </section>
    )
}
