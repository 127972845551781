import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Creativity from '../Img/creativity_1.png'
import Applicatiion from '../Img/application.png'
import MobileDevelopment from '../Img/mobile-development.png'
import Laptop from '../Img/laptop.png'
import Ux from '../Img/ux-design.png'
import Planning from  '../Img/planning.png'


export default function services() {

        const  servicesdata =[
            {
                img: Creativity,
                Number: "1",
                title: "Creative Idea",
                description: "Give your ideas that life and to make your business coordinate effectively and work smoothly."
            },
            {
                img: Ux,
                Number: "2",
                title: "UI/UX Designer",
                description: "Design is everywhere. From the dress you’re wearing to the smartphone you’re holding, it’s design."
            },
            {
                img: Planning,
                Number: "3",
                title: "Business Planning",
                description: "Create your own team of developers for your software development project on short term, long term or permanent basis with guaranteed project delivery at affordable prices."
            },
            {
                img: MobileDevelopment,
                Number: "4",
                title: "Mobile App Development",
                description: "We develop mobile apps that help businesses for better operational efficiency and engagement."
            },
            {
                img: Laptop,
                Number: "5",
                title: "Web Development",
                description: "For 6+ years now, we’ve mastered the top web technologies to deliver projects that have raked in investments."
            },
            {
                img: Applicatiion,
                Number: "6",
                title: "Program management",
                description: "The development of your next business plan will be executed by a brilliant team who will indicate your grand success."
            }
        ]
    return (
        <section className='services'>
            <Container  data-aos="fade-up">
                <div className='design_2'>
                    <span></span>
                    <h3>services</h3>
                    <h4>CHECK OUR SERVICE</h4>
                    <p>We create stunning designs and high-quality work.</p>
                </div>
                <Row className='justify-content-center'>
                    {
                        servicesdata.map((data,index)=>{
                            return(
                            <Col lg={4} md={5} sm={6} className="my-2 d-flex">
                                <div className='creative-idea'>
                                    <div className='creative-icon'>
                                        <img src={data.img} alt="" />
                                        <span>{data.Number}</span>
                                    </div>
                                    <h3>{data.title}</h3>
                                    <p>{data.description}</p>
                                </div>
                            </Col>
                            )
                        })
                    }
                </Row>
            </Container>
           
        </section>
    )
}
