import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Ebookmobile from '../Img/Ebookmobile.png'

export default function AppFeatures() {
    return (
        <section className='UserFeatures'>
            <Container>
                <div className='round-shape'>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span className='bg-color-3'></span>
                </div>
            </Container>
            <Container className='mb-4'>
                <div class="design_2">
                    <span></span>
                    <h4>Features</h4>
                </div>
            </Container>
            <Container fluid>
                <Row className='justify-content-center'>
                    <Col lg={4}>
                        <div className='Discover'>
                            <div className="Shuffle-Mode right">
                                <p>Save ebook to favorite for offline reading.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode right">
                                <p>Category wise eBook.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode right">
                                <p>Look up definitions, get translations, save highlights and bookmark your favourite pages while you read.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode right">
                                <p>Bookmark with laset reading page</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode right">
                                <p>Landscape or portrait orientation is lockable</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode right">
                                <p>Online synchronization for bookmarks, highlights, notes and current reading position with all your Android and iOS devices</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode right">
                                <p>Receive notifications or emails for price drops in your samples and new releases from your favourite authors and series.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={12}>
                        <div className="Discover text-center">
                            <img className='w-70 text-center' src={Ebookmobile} alt="" />
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className='Discover'>
                            <div className="Shuffle-Mode">
                                <p>Online backup - your online ebook library is always accessible, so anytime you get a new phone, your books</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode">
                                <p>Track your reading progress. See what percent you’ve read and how many pages you have left.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode">
                                <p>Look up definitions, get translations, save highlights and bookmark your favourite pages while you read.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode">
                                <p>Take notes that sync with your Google Drive and share them with a group for easy collaboration.</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode">
                                <p>Awesome settings for comfortable reading</p>
                            </div>
                        </div>
                        <div className='Discover'>
                            <div className="Shuffle-Mode">
                                <p>Save to SD card. Choose to save your books to the device or an SD card, so you never run out of space.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
