import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Container } from "react-bootstrap";
import FlutterApp_1 from '../Img/FlutterApp/FlutterApp-1.png'
import FlutterApp_2 from '../Img/FlutterApp/FlutterApp-2.png'
import FlutterApp_3 from '../Img/FlutterApp/FlutterApp-3.png'
import FlutterApp_4 from '../Img/FlutterApp/FlutterApp-4.png'
import FlutterApp_5 from '../Img/FlutterApp/FlutterApp-5.png'
import FlutterApp_6 from '../Img/FlutterApp/FlutterApp-6.png'
import FlutterApp_7 from '../Img/FlutterApp/FlutterApp-7.png'
import FlutterApp_8 from '../Img/FlutterApp/FlutterApp-8.png'
import FlutterApp_9 from '../Img/FlutterApp/FlutterApp-9.png'
import FlutterApp_10 from '../Img/FlutterApp/FlutterApp-10.png'
import FlutterApp_11 from '../Img/FlutterApp/FlutterApp-11.png'
import FlutterApp_12 from '../Img/FlutterApp/FlutterApp-12.png'
import FlutterApp_13 from '../Img/FlutterApp/FlutterApp-13.png'
import FlutterApp_14 from '../Img/FlutterApp/FlutterApp-14.png'
import FlutterApp_15 from '../Img/FlutterApp/FlutterApp-15.png'
import FlutterApp_16 from '../Img/FlutterApp/FlutterApp-16.png'
import FlutterApp_17 from '../Img/FlutterApp/FlutterApp-17.png'


export default function FlutterAppPlaceFinder() {
  return (
    <section className='Mobile'>
         <Container fluid>
                <Swiper
                    breakpoints={{
                        300: {
                            slidesPerView: 2,
                        },
                        499: {
                            slidesPerView: 3,
                        },
                        999: {
                            slidesPerView: 5,
                        },
                        1100: {
                            slidesPerView: 7,
                        }
                    }}
                    slidesPerView={7}
                    spaceBetween={30}
                    freeMode={true}
                    grabCursor={true}
                    loop={true}
                    centeredSlides={true}
                    pagination={{
                        clickable: true,
                        el: Swiper-Pagination,

                    }}
                    modules={[FreeMode, Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={FlutterApp_1} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={FlutterApp_2} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={FlutterApp_3} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={FlutterApp_4} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={FlutterApp_5} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={FlutterApp_6} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={FlutterApp_7} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={FlutterApp_8} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={FlutterApp_9} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={FlutterApp_10} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={FlutterApp_11} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={FlutterApp_12} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={FlutterApp_13} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={FlutterApp_14} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={FlutterApp_15} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={FlutterApp_16} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={FlutterApp_17} alt="" />
                        </div>
                    </SwiperSlide>
                </Swiper>
            </Container>
    </section>
  )
}
