import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function purpose() {
    return (
        <section className='purpose'>
            <Container>
                <Row>
                    <Col lg={5} className='my-2'>
                        <div className='design'>
                            <span></span>
                            <h3>OUR PURPOSE</h3>
                        </div>
                        <h4 className='Developer'>WE PLAN YOUR PROJECT PROCESS</h4>
                        <a href=' mailto:evenmore.in@gmail.com' target="_blank"><p className='Top-rated'>evenmore.in@gmail.com</p></a>
                        <a href=' mailto:evenmore.in@gmail.com' target="_blank"><Button className='Explore'>connect with us <FontAwesomeIcon className='middle' color='#192734' icon={faArrowRight} /></Button></a>
                    </Col>
                    <Col lg={7} className='my-2'>
                        <Row>
                            <Col lg={6} md={6} className="shape">
                                <div className='Discover' data-aos="flip-left">
                                    <h5>1. Discover</h5>
                                    <p>We shape brands through exploration, applying in- depth research to challenge assumptions at every turn.</p>
                                </div>
                                <div className='Discover' data-aos="flip-left">
                                    <h5>2. Design</h5>
                                    <p>Our design approach is to simplify. We embrace the joy in creating something unique that is easy for end users.</p>
                                </div>
                            </Col>
                            <Col lg={6} md={6} className="shape">
                                <div className='Discover' data-aos="flip-left">
                                    <h5>3. Build</h5>
                                    <p>Using modern technologies, we build with efficiency and skill, creating flexible and scalable business-driven solutions.</p>
                                </div>
                                <div className='Discover' data-aos="flip-left">
                                    <h5>4. Deliver</h5>
                                    <p>We take an iterative approach to both our work and our practice, always looking for ways to improve what we do.</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
