import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Management from '../Img/management.png'
import goal from '../Img/Goal.png'
import mission from '../Img/mission.png'
import visionary from '../Img/visionary.png'


export default function Four() {
  return (
    <section className='four' data-aos="fade-up">
        <Container>
            <Row className='justify-content-center'>
                <Col lg={6} md={12} sm={12}>
                    <div className='Our-Mission'>
                        <div className='Our-Mission-img'>
                            <img src={Management} alt="" />
                        </div>
                        <div className='OurMission-sub'>
                            <h5>Our Mission</h5>
                            <p>Our mission is a key strengths & commitment to total customer satisfaction, highly qualified Experts, multinational resource pool, availability, flexibility, reliability and fast turnaround. Our goal is to be your first contact when looking for the right solution to your business needs.</p>
                        </div>
                    </div>
                    <div className='Our-Mission'>
                        <div className='Our-Mission-img'>
                            <img src={mission} alt="" />
                        </div>
                        <div className='OurMission-sub'>
                            <h5>Our Goal</h5>
                            <p>Our goal is to develop enduring relationships with clients across the globe, by providing highly qualified professional and streamlined services at cost effective and competitive prices.</p>
                        </div>
                    </div>
                    <div className='Our-Mission'>
                        <div className='Our-Mission-img'>
                            <img src={visionary} alt="" />
                        </div>
                        <div className='OurMission-sub'>
                            <h5>Our Vision</h5>
                            <p>Our vision is to give your ideas that life and to make your business coordinate effectively and work smoothly.</p>
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={12} sm={12}>
                    <div className='goal'>
                        <img className='w-100' src={goal} alt="" />
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
  )
}
