import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import Flutter_img from '../Img/flutter_img.png'

export default function FlutterPlace() {
    return (
        <section className='Relaxioo'>
            <Container>
                <Row>
                    <Col lg={{ span: 6, order: 1 }} md={{ span: 12, order: 2 }} xs={{ span: 12, order: 2 }} className='align-self-center my-3'>
                        <div className='Relaxioo-main'>
                            <h2>Flutter App Place Finder With Admin Panel</h2>
                            <div className='view-demo'>
                                <a href=' https://codecanyon.net/item/flutter-appplace-finder-near-metourist-guidecity-guideexplore-location-with-admin-panel/25431625?s_rank=6' target="_blank">
                                    <Button className='Explore'> <span><svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.58333 1.6875C1.58333 1.23995 1.771 0.810725 2.10505 0.494257C2.4391 0.17779 2.89217 0 3.36458 0H15.6354C16.1078 0 16.5609 0.17779 16.895 0.494257C17.229 0.810725 17.4167 1.23995 17.4167 1.6875V8.4375C17.4167 8.88505 17.229 9.31427 16.895 9.63074C16.5609 9.94721 16.1078 10.125 15.6354 10.125H3.36458C2.89217 10.125 2.4391 9.94721 2.10505 9.63074C1.771 9.31427 1.58333 8.88505 1.58333 8.4375V1.6875ZM0.59375 10.875C0.436278 10.875 0.285255 10.9343 0.173905 11.0398C0.0625556 11.1452 0 11.2883 0 11.4375C0 11.5867 0.0625556 11.7298 0.173905 11.8352C0.285255 11.9407 0.436278 12 0.59375 12H18.4062C18.5637 12 18.7147 11.9407 18.8261 11.8352C18.9374 11.7298 19 11.5867 19 11.4375C19 11.2883 18.9374 11.1452 18.8261 11.0398C18.7147 10.9343 18.5637 10.875 18.4062 10.875H0.59375Z" fill="white" />
                                    </svg>
                                    </span> View demo</Button>

                                </a>
                            </div>

                        </div>
                    </Col>
                    <Col lg={{ span: 6, order: 1 }} md={{ span: 12, order: 1 }} xs={{ span: 12, order: 1 }}>
                        <div className='relaxioo-image'>
                            <img className='w-100' src={Flutter_img} alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
