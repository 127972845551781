import React from 'react'
import { Container, Nav, Row, Tab } from 'react-bootstrap'
import Angular from '../Img/Angular.png'
import Reactjs from '../Img/reactjs.png'
import Vue from '../Img/vue js.png'
import Nextjs from '../Img/nextjs-icon.png'
import Nuxtjs from '../Img/nuxt-js-icon.png'
import Java from '../Img/java.png'
import Node from '../Img/node js.png'
import Php from '../Img/php.png'
import codeigniter from '../Img/codeigniter-icon.png'
import pngegg from '../Img/pngegg.png'
import MySQL from '../Img/MySQL-Logo.png'
import MS_SQL from '../Img/MS-SQL.png'
import MongoDB from '../Img/MongoDB.png'
import Firebase from '../Img/Firebase.png'
import Wordpress from '../Img/Wordpress.png'
import Codeigniter from '../Img/codeigniter-icon1.png'
import Android from '../Img/android.png'
import iOS from '../Img/iOS.png'
import Flutter from '../Img/flutter.png'
import AWS from '../Img/AWS.png'
import Docker from '../Img/Docker.png'
import Postman from '../Img/Postman.png'
import Swagger from '../Img/Swagger.png'





export default function skills() {
    return (
        <section className='skills'>
            <Container  data-aos="fade-up">
                <div className='design_2'>
                    <span></span>
                    <h3>skills</h3>
                    <h4>We Specialize In</h4>
                    <p>We work with you to achieve your goals.</p>
                </div>
                <div className='skills-tab'>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <div className='front-main'>
                            <Nav variant="pills">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Front End </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Back End</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third">Database</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fourth">Mobile</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fifth">DevOps and Cloud</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="sixth">CMS</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Seventh">Testing</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <Row className='mt-5'>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <ul className='list-main'>
                                        <li>
                                            <div className='first-img'>
                                                <img src={Angular} alt="" />
                                            </div>
                                            <h3>Angular</h3>
                                        </li>
                                        <li>
                                            <div className='first-img'>
                                                <img src={Reactjs} alt="" />
                                            </div>
                                            <h3>React</h3>
                                        </li>
                                        <li>
                                            <div className='first-img'>
                                                <img src={Vue} alt="" />
                                            </div>
                                            <h3>Vue</h3>
                                        </li>
                                        <li>
                                            <div className='first-img'>
                                                <img src={Nextjs} alt="" />
                                            </div>
                                            <h3>Next</h3>
                                        </li>
                                        <li>
                                            <div className='first-img'>
                                                <img src={Nuxtjs} alt="" />
                                            </div>
                                            <h3>NuxtJS</h3>
                                        </li>
                                    </ul>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <ul className='list-main'>
                                        <li>
                                            <div className='first-img'>
                                                <img src={Java} alt="" />
                                            </div>
                                            <h3>Java</h3>
                                        </li>
                                        <li>
                                            <div className='first-img'>
                                                <img src={Php} alt="" />
                                            </div>
                                            <h3>PHP</h3>
                                        </li>
                                        <li>
                                            <div className='first-img'>
                                                <img src={Node} alt="" />
                                            </div>
                                            <h3>NodeJS</h3>
                                        </li>
                                        <li>
                                            <div className='first-img'>
                                                <img src={pngegg} alt="" />
                                            </div>
                                            <h3>Laravel</h3>
                                        </li>
                                        <li>
                                            <div className='first-img'>
                                                <img src={codeigniter} alt="" />
                                            </div>
                                            <h3>Codeigniter</h3>
                                        </li>
                                    </ul>

                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <ul className='list-main'>
                                        <li>
                                            <div className='first-img'>
                                                <img src={MySQL} alt="" />
                                            </div>
                                            <h3>MySQL</h3>
                                        </li>
                                        <li>
                                            <div className='first-img'>
                                                <img src={MS_SQL} alt="" />
                                            </div>
                                            <h3>MS-SQL</h3>
                                        </li>
                                        <li>
                                            <div className='first-img'>
                                                <img src={MongoDB} alt="" />
                                            </div>
                                            <h3>MongoDB</h3>
                                        </li>
                                        <li>
                                            <div className='first-img'>
                                                <img src={Firebase} alt="" />
                                            </div>
                                            <h3>Firebase</h3>
                                        </li>

                                    </ul>

                                </Tab.Pane>
                                <Tab.Pane eventKey="fourth">
                                    <ul className='list-main'>
                                        <li>
                                            <div className='first-img'>
                                                <img src={Android} alt="" />
                                            </div>
                                            <h3>Android</h3>
                                        </li>
                                        <li>
                                            <div className='first-img'>
                                                <img src={iOS} alt="" />
                                            </div>
                                            <h3>iOS</h3>
                                        </li>
                                        <li>
                                            <div className='first-img'>
                                                <img src={Flutter} alt="" />
                                            </div>
                                            <h3>Flutter</h3>
                                        </li>
                                        <li>
                                            <div className='first-img'>
                                                <img src={Reactjs} alt="" />
                                            </div>
                                            <h3>React</h3>
                                        </li>

                                    </ul>

                                </Tab.Pane>
                                <Tab.Pane eventKey="fifth">
                                    <ul className='list-main'>
                                        <li>
                                            <div className='first-img'>
                                                <img src={AWS} alt="" />
                                            </div>
                                            <h3>AWS</h3>
                                        </li>
                                        <li>
                                            <div className='first-img'>
                                                <img src={Docker} alt="" />
                                            </div>
                                            <h3>Docker</h3>
                                        </li>
                                    </ul>
                                </Tab.Pane>
                                <Tab.Pane eventKey="sixth">
                                    <ul className='list-main'>
                                        <li>
                                            <div className='first-img'>
                                                <img src={Wordpress} alt="" />
                                            </div>
                                            <h3>Wordpress</h3>
                                        </li>
                                        <li>
                                            <div className='first-img'>
                                                <img src={Codeigniter} alt="" />
                                            </div>
                                            <h3>Codeigniter</h3>
                                        </li>

                                    </ul>
                                </Tab.Pane>
                                <Tab.Pane eventKey="Seventh">
                                    <ul className='list-main'>
                                        <li>
                                            <div className='first-img'>
                                                <img src={Postman} alt="" />
                                            </div>
                                            <h3>Postman</h3>
                                        </li>
                                        <li>
                                            <div className='first-img'>
                                                <img src={Swagger} alt="" />
                                            </div>
                                            <h3>Swagger</h3>
                                        </li>

                                    </ul>
                                </Tab.Pane>
                            </Tab.Content>
                        </Row>
                    </Tab.Container>

                </div>
            </Container>
           
        </section>
    )
}
