import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay ,FreeMode, Pagination } from "swiper";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Container } from "react-bootstrap";
import logo_1 from '../Img/client-logo/logo-1.png'
import logo_2 from '../Img/client-logo/logo-2.png'
import logo_3 from '../Img/client-logo/logo-3.png'
import logo_4 from '../Img/client-logo/logo-4.png'
import logo_5 from '../Img/client-logo/logo-5.png'
import logo_6 from '../Img/client-logo/logo-6.png'

export default function clientsLogo() {
  return (
    <section className='clientsLogo'>
        <Container>
                <Swiper
                    breakpoints={{
                        200: {
                            slidesPerView: 1,
                        },
                        300: {
                            slidesPerView: 2,
                        },
                        499: {
                            slidesPerView: 3,
                        },
                        999: {
                            slidesPerView: 5,
                        },
                        1100: {
                            slidesPerView: 6,
                        }
                    }}
                    slidesPerView={6}
                    spaceBetween={40}
                    autoplay={true}
                    freeMode={true}
                    grabCursor={true}
                    loop={true}
                    // pagination={{
                    //     clickable: true,
                    //     el: Swiper-Pagination,

                    // }}
                    navigation={true}
                    modules={[Autoplay, FreeMode, Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className="logo-img">
                            <img src={logo_1} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="logo-img">
                            <img src={logo_2} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="logo-img">
                            <img src={logo_3} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="logo-img">
                            <img src={logo_4} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="logo-img">
                            <img src={logo_5} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="logo-img">
                            <img src={logo_6} alt="" />
                        </div>
                    </SwiperSlide>
                   
                   
                </Swiper>
            </Container>
    </section>
  )
}
