import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export default function subCategory() {
    return (
        <section className='sub-category'>
            <Container>
                <Row>
                    <Col lg={3} md={6} sm={6} col={12} data-aos="flip-left" className="my-2">
                        <div className='ui-ux'>
                            <h3>UI/UX Design</h3>
                            <ul>
                                <li>Web UI/UX Design</li>
                                <li>App UI/UX Design</li>
                                <li>Prototype</li>
                            </ul>
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={6} col={12} data-aos="flip-left" className="my-2">
                        <div className='ui-ux'>
                            <h3>Mobile App Development</h3>
                            <ul>
                                <li>Android App Development</li>
                                <li>iOS App Development</li>
                                <li>Flutter App Development</li>
                            </ul>
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={6} col={12} data-aos="flip-left" className="my-2">
                        <div className='ui-ux'>
                            <h3>Frontend</h3>
                            <ul>
                                <li>HTML/CSS/JS</li>
                                <li>Bootstrap 4-5 </li>
                                <li>Responsive Web Design</li>
                                <li>Interactive Animation Website</li>
                                <li>Email Template</li>
                            </ul>
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={6} col={12} data-aos="flip-left" className="my-2">
                        <div className='ui-ux'>
                            <h3>Backend</h3>
                            <ul>
                                <li>PHP</li>
                                <li>Magento 2</li>
                                <li>Wordpress</li>
                                <li>Prestashop</li>
                                <li>Web Speed Optimization</li>
                                <li>E-commerce</li>  
                                <li>node js</li>  
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
