import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function clientPortfolio() {
  return (
    <section className='about-page'>
      <Container>
        <div className='about-main'>
          <h3 data-aos="fade-down">Portfolio</h3>
          <div className='design_2'>
            <span></span>
          </div>
          <div className='sub-demo'>
            <Link to="/" className='page-section'><span onClick={()=>{document.body.scrollTop = document.documentElement.scrollTop = 0;}}>Home</span></Link>
            <span>/ portfolio</span>
          </div>
        </div>
      </Container>
    </section>
  )
}
