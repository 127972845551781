import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Login from '../Img/our-culture/login1.png'
import book from '../Img/our-culture/book.png'
import checklist from '../Img/our-culture/checklist.png'
import cheerup from '../Img/our-culture/cheer-up.png'
import collaborate from '../Img/our-culture/collaborate.png'
import givelove from '../Img/our-culture/give-love.png'
import Happy from '../Img/our-culture/happy.png'
import teamleader from '../Img/our-culture/team-leader.png'
import valuable from '../Img/our-culture/valuable.png'

export default function ourculture() {
    const allimages = [
        {
            img: Login,
            title: "User-Friendly"

        },
        {
            img: givelove,
            title: "Caring"

        },
        {
            img: teamleader,
            title: "Caring"

        },
        {
            img: book,
            title: "Learning"

        },
        {
            img: cheerup,
            title: "Enthusiastic"

        },
        {
            img: Happy,
            title: "Happy"

        },
        {
            img: collaborate,
            title: "Integrity"

        },
        {
            img: checklist,
            title: "Accountability"

        },
        {
            img: valuable,
            title: "Valuable"

        },

    ]
    return (
        <section className='ourculture'>
            <Container>
                <div class="design_2">
                    <span></span>
                    <h4>our culture</h4>
                </div>
                <Row className='mt-5 justify-content-center'>
                    {
                        allimages.map((data, index) => {
                            return (
                                <Col lg={3} md={4} sm={6}>
                                    <div className='ourculture-info'>
                                        <img src={data.img} alt="" />
                                        <h3>{data.title}</h3>
                                    </div>
                                </Col>

                            )
                        })
                    }
                </Row>
            </Container>
        </section>
    )
}
