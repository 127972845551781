import React from 'react'
import { Container } from 'react-bootstrap'
import activities from '../Img/Activitiesimage/activities-1.png'
import activities_2 from '../Img/Activitiesimage/activities-2.png'
import activities_3 from '../Img/Activitiesimage/activities-3.png'
import activities_4 from '../Img/Activitiesimage/activities-4.png'
import activities_5 from '../Img/Activitiesimage/activities-5.png'
import activities_6 from '../Img/Activitiesimage/activities-6.png'
import activities_7 from '../Img/Activitiesimage/activities-7.png'
import activities_8 from '../Img/Activitiesimage/activities-8.png'
import activities_9 from '../Img/Activitiesimage/activities-9.png'
import activities_10 from '../Img/Activitiesimage/activities-10.png'
import activities_11 from '../Img/Activitiesimage/activities-11.png'
import activities_12 from '../Img/Activitiesimage/activities-12.png'
import activities_13 from '../Img/Activitiesimage/activities-13.png'
import activities_14 from '../Img/Activitiesimage/activities-14.png'
import activities_15 from '../Img/Activitiesimage/activities-15.png'
import activities_16 from '../Img/Activitiesimage/activities-16.png'
import activities_17 from '../Img/Activitiesimage/activities-17.png'
import activities_18 from '../Img/Activitiesimage/activities-18.png'
import activities_19 from '../Img/Activitiesimage/activities-19.png'
import activities_20 from '../Img/Activitiesimage/activities-20.png'
import activities_21 from '../Img/Activitiesimage/activities-21.png'
import activities_22 from '../Img/Activitiesimage/activities-22.png'
import activities_23 from '../Img/Activitiesimage/activities-23.png'

export default function Activitiesimage() {
    return (
        <section className='Activitiesimage' data-aos="fade-up">
            <Container>
                <div className='subrow'>
                    <figure className='main-figure sub-demo'>
                        <img className='imgdemo' src={activities} alt="" />
                    </figure>
                    <figure className='main-figure sub-demo-2'>
                        <img className='imgdemo' src={activities_2} alt="" />
                    </figure>
                    <figure className='main-figure sub-demo'>
                        <img className='imgdemo' src={activities_3} alt="" />
                    </figure>
                    <figure className='main-figure'>
                        <img className='imgdemo' src={activities_4} alt="" />
                    </figure>
                    <figure className='main-figure'>
                        <img className='imgdemo' src={activities_5} alt="" />
                    </figure>
                    <figure className='main-figure'>
                        <img className='imgdemo' src={activities_6} alt="" />
                    </figure>
                    <figure className='main-figure'>
                        <img className='imgdemo' src={activities_7} alt="" />
                    </figure>
                    <figure className='main-figure sub-demo-5'>
                        <img className='imgdemo' src={activities_18} alt="" />
                    </figure>
                    <figure className='main-figure sub-demo-2'>
                        <img className='imgdemo' src={activities_8} alt="" />
                    </figure>
                    <figure className='main-figure sub-demo-2'>
                        <img className='imgdemo' src={activities_9} alt="" />
                    </figure>
                    <figure className='main-figure sub-demo-2'>
                        <img className='imgdemo' src={activities_10} alt="" />
                    </figure>
                    <figure className='main-figure sub-demo'>
                        <img className='imgdemo' src={activities_11} alt="" />
                    </figure>
                    <figure className='main-figure sub-demo-4'>
                        <img className='imgdemo' src={activities_12} alt="" />
                    </figure>
                    <figure className='main-figure sub-demo-4'>
                        <img className='imgdemo' src={activities_13} alt="" />
                    </figure>
                    <figure className='main-figure sub-demo-2'>
                        <img className='imgdemo' src={activities_14} alt="" />
                    </figure>
                    <figure className='main-figure sub-demo'>
                        <img className='imgdemo' src={activities_15} alt="" />
                    </figure>
                    <figure className='main-figure sub-demo'>
                        <img className='imgdemo' src={activities_16} alt="" />
                    </figure>
                    <figure className='main-figure'>
                        <img className='imgdemo' src={activities_17} alt="" />
                    </figure>
                    <figure className='main-figure'>
                        <img className='imgdemo' src={activities_21} alt="" />
                    </figure>
                    <figure className='main-figure sub-demo'>
                        <img className='imgdemo' src={activities_19} alt="" />
                    </figure>
                    <figure className='main-figure sub-demo'>
                        <img className='imgdemo' src={activities_20} alt="" />
                    </figure>
                    <figure className='main-figure sub-demo'>
                        <img className='imgdemo' src={activities_23} alt="" />
                    </figure>
                    <figure className='main-figure'>
                        <img className='imgdemo' src={activities_22} alt="" />
                    </figure>
                </div>

            </Container>
        </section>
    )
}
