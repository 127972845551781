import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function Activities() {
  return (
    <section className='about-page'>
      <Container>
        <div className='about-main'>
          <h3 data-aos="fade-down">Evenmore Activities</h3>
          <div className='design_2'>
            <span></span>
          </div>
          <div className='sub-demo'>
            <Link to="/" className='page-section'>Home </Link>
            <span>/ Activities</span>
          </div>
        </div>
      </Container>
    </section>
  )
}
