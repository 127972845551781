import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import evenmoreWords from '../Img/EvenmoreWords.png'
import core from '../Img/core.png'

export default function Core() {
  return (
    <section className='core'>
        <Container fluid>
                <Row>
                    <Col lg={6} md={6}>
                        <div className='core-bg'>
                            <img src={evenmoreWords} className='top-margin' alt="" />
                        </div>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className='core-value'>
                                <img className='w-100' src={core}  alt=""/>
                        </div>
                    </Col>
                </Row>
        </Container>
    </section>
  )
}
