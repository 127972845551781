import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import application1 from '../Img/application1.png'
import money from '../Img/money.png'
import music from '../Img/music.png'

export default function Simplest() {
    return (
        <section className='Simplest'>
            <Container>
                <Row className='justify-content-center'>
                    <Col lg={4} md={8} sm={12} data-aos="flip-up" className="my-2">
                        <div className='user'>
                            <img className='mb-4' src={application1} alt="" />
                            <h3>User Friendly Apps</h3>
                            <p>Box Music apps are so user friendly that anyone can use them.</p>

                        </div>
                    </Col>
                    <Col lg={4} md={8} sm={12} data-aos="flip-up" className="my-2">
                        <div className='user'>
                            <img className='mb-4' src={music} alt="" />
                            <h3>Beautiful Interface</h3>
                            <p>All the app of Box Music software has a very attractive outlook.</p>

                        </div>
                    </Col>
                    <Col lg={4} md={8} sm={12} data-aos="flip-up" className="my-2">
                        <div className='user'>
                            <img className='mb-4' src={money} alt="" />
                            <h3>Minimal costing</h3>
                            <p>The costing of this apps is so low that any of person can bear.</p>

                        </div>
                    </Col>
                </Row>
            </Container>

        </section>
    )
}
