
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'



export default function experience() {
    return (
        <section className='experience'>
            <Container>
                <Row>
                    <Col lg={5} md={12} className="my-2" >
                        <div className='experience-main'  data-aos="fade-up">
                            <div className='design'>
                                <span></span>
                                <h3>ABOUT US</h3>
                            </div>
                            <h4 className='Developer'>Hello, We Are Developer</h4>
                            <p className='Top-rated'>Top-rated Web And Mobile App Development Company</p>
                        </div>
                    </Col>
                    <Col lg={7} md={12} className="my-2">
                        <div className='experience-main_2'>
                            <h4>6+ years of <span>experience</span></h4>
                            <p>Evenmore have extensive experience in web design and development, graphic design, mobile application development and online marketing. We contribute to one another’s thoughts, ideas and pull together our passion and dedication to give our clients the very best quality of our work to maintain our reputation.</p>
                            <div className='choose'>
                                <h5>Why choose us?</h5>
                                <ul>
                                    <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                    </svg>
                                        <span>We build Powerful Websites</span>
                                    </li>
                                    <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                    </svg>
                                        <span>Highly Experienced Professional Team</span>
                                    </li>
                                    <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                    </svg>
                                        <span>Up-to-Date with the Latest Standards</span>
                                    </li>
                                    <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                    </svg>
                                        <span>We’re Affordable</span>
                                    </li>
                                    <li><svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.855 2.925V4.08H0.87V2.925H6.855ZM9.67172 0.0749996H11.3967L15.0267 3.51L11.3967 6.96H9.67172L13.3017 3.51L9.67172 0.0749996Z" fill="#5B5B5B" />
                                    </svg>
                                        <span>We’re Affordable</span>
                                    </li>
                                </ul>
                               
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
